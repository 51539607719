<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title
          >Planning: &nbsp;{{ plan.date_from }}&nbsp;TO&nbsp;{{ plan.date_to }}
        </v-card-title>
        <v-card-text>
          <listitems
            :list="infos"
            :headers="headers"
            :toolbar="false"
            :add="false"
            :del="false"
            :key="cor_key2"
          >
          </listitems>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ma-1" color="blue darken-1" @click="closeDialog()">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog></v-form
  >
</template>
<script>
export default {
  components: { listitems: () => import("../components/ListItems.vue") },
  props: {
    dialog: Boolean,
    infos: Array,
    plan: Object,
  },
  data() {
    return {
      cor_key2: 0,
      headers: [
        {
          text: "Profile",
          value: "profile",
          selected: true,
        },
        {
          text: "Waivers Task",
          value: "waivers",
          selected: true,
        },
        {
          text: "Accept",
          value: "accepted",
          selected: true,
        },
        {
          text: "Validation",
          value: "validated",
          selected: true,
        },
      ],
    };
  },
  watch: {},
  mounted() {
    this.cor_key2++;
  },
  computed: {},
  methods: {
    closeDialog() {
      this.$emit("CloseInfos");
    },
  },
};
</script>
